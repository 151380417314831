<template>
  <!-- 宠物粮食详情页面 -->
  <moe-page title="宠物粮食详情">
    <moe-describe-list :col="3" title="宠物粮食信息">
      <moe-describe-item label="宠物粮食品牌"
        >{{ cereal.brand }}
      </moe-describe-item>
      <moe-describe-item label="宠物类型"
        >{{ $moe_format.getPetType(cereal.pesType) }}
      </moe-describe-item>
      <moe-describe-item label="品牌主图">
        <moe-image
          style="width: 100px; height: 100px"
          :src="cereal.img"
          width="100px"
          height="100px"
        />
      </moe-describe-item>
      <moe-describe-item label="宠物粮食名称">
        <div class="cereal-list">
          <div class="cereal-item" v-for="(item, index) of cereal.names" :key="index">
            {{ item.name }}
          </div>
        </div>
      </moe-describe-item>
    </moe-describe-list>
  </moe-page>
</template>

<script>
export default {
  name: 'PetCerealDetail',
  data() {
    return {
      cereal: {}, //宠物粮食信息
    };
  },
  created() {
    //获取宠物粮食详情
    this.getCerealDetail();
  },
  methods: {
    /**
     * 获取宠物粮食详情
     **/
    getCerealDetail() {
      const id = this.$route.query.id;
      // 调用所有角色API
      this.$moe_api.PET_API.petCerealDetail({ id }).then((data) => {
        if (data.code == 200) {
          let c = data.result;

          // 回显宠物分类
          this.cereal = {
            id: c.id, //粮食ID
            brand: c.brand, //品牌
            pesType: c.pesType, //宠物类型
            names: c.names, //品牌名称
            img: c.img, //品牌主图
          };
        } else {
          this.$moe_msg.error(data.message);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.cereal-item {
  display: flex;
  margin-bottom: 15px;
}
</style>
